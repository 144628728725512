/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/audio.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/before-after.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/blockquote.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/bookmark.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/button.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/callout.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/file.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/gallery.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/header.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/nft.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/product.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/toggle.min.css
 * - /npm/ghost@5.12.0/core/frontend/src/cards/css/video.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
